<template>
  <div class="add">
    <ShopNavBar title="修改商品" />

    <div class="form">
      <van-field v-model="goods.number" label="编号" placeholder="请输入编号" />
      <van-field v-model="goods.name" label="商品名称" placeholder="请输入商品名称" />
      <van-field v-model="goods.label" label="提示语" placeholder="请输入提示语" />
      <van-field v-model="fenlei.name" readonly label="分类" placeholder="请选择分类" @click="show = true" />
      <div class="cell">
        <div class="cell-label">上架</div>
        <van-switch v-model="goods.state" :active-value="1" :inactive-value="0" size="24" active-color="#ee0a24" />
      </div>
      <div class="cell">
        <div class="cell-label">规格</div>
        <div class="row">
          <div class="sku-btn" @click="addModel">添加规格</div>
          <div class="sku-btn" @click="delModel">删除规格</div>
        </div>
      </div>
    </div>

    <div class="form sku-form">
      <div class="q-mb-sm">
        <van-tabs v-model:active="active">
          <van-tab :title="item.name" v-for="(item, index) in shopGoods2Model" :key="index"></van-tab>
        </van-tabs>
      </div>
      <div v-for="(item, index) in shopGoods2Model" :key="index" v-show="active == index">
        <van-field v-model="item.name" label="规格名称" placeholder="请输入规格名称" />
        <div class="cell">
          <div class="cell-label">零售价</div>
          <van-stepper v-model="item.price" min="0" />
        </div>
        <div class="cell">
          <div class="cell-label">让利</div>
          <van-stepper v-model="item.rangli" min="0" />
          <div class="q-ml-sm">%</div>
        </div>
        <div class="cell">
          <div class="cell-label">库存</div>
          <van-stepper v-model="item.stock" min="0" />
        </div>
        <div class="cell">
          <div class="cell-label">排序</div>
          <van-stepper v-model="item.sort" min="0" />
        </div>
        <div class="cell">
          <div class="cell-label">上架</div>
          <van-switch v-model="item.state" :active-value="1" :inactive-value="0" size="24" active-color="#ee0a24" />
        </div>
        <div class="cell">
          <div class="cell-label">图片</div>
          <SeleteSlide :uploadImgServerUrl="UploadFile" :uploadVideoServerUrl="UploadVideoFile"
							:imgListAndVideoList="item.slideList" @getFileList="getFileList" :cover="item.img"
							@getCover="getCover" />
        </div>
      </div>
      <div v-for="(item, index) in shopGoods2Model" :key="index">
        <WangEditor :txtContent="item.content" :isClear="false" :key="item.id" @change="editoChange" v-if="active == index"></WangEditor>
      </div>
    </div>

    <div class="footer" @click="update">
      <q-icon name="edit" size="18px"></q-icon>
      <div>确认修改</div>
    </div>

    <!-- 选择分类 -->
    <van-popup v-model:show="show" position="bottom">
      <van-picker
        title="选择分类"
        :columns="fenleiList"
        confirm-button-text="确定"
        cancel-button-text="取消"
        @confirm="onConfirm"
        @cancel="show = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { Field, Cell, Switch, Tab, Tabs, Stepper, Uploader, Toast, Popup, Picker } from 'vant';
import { ref } from 'vue';
import Axios from "axios";
import WangEditor from "@/components/WangEditor";
import SeleteSlide from "@/components/SeleteSlide";

export default {
  components: {
    [Field.name]: Field,
    [Cell.name]: Cell,
    [Switch.name]: Switch,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Stepper.name]: Stepper,
    [Uploader.name]: Uploader,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    WangEditor,
    SeleteSlide
  },
  setup() {
    return {
      UploadFile: ref(""),
      UploadVideoFile: ref(""),
      us: ref(null),
      id: ref(""),
      goods: ref({
        number: "",
        name: "",
        label: "",
        type: 1,
        class2Id: {},
        sort: 0,
        state: 0,
        homePage: 0
      }),
      shopGoods2Model: ref([{
        name: "规格1",
        price: 0,
        stock: 0,
        sales: 0,
        content: "",
        img: [],
        fileList: [],
        state: 1,
        sort: 0,
        rangli: 0
      }]),
      fileList: ref([]),
      active: ref(0),
      show: ref(false),
      fenlei: ref({}),
      fenleiList: ref([])
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.UploadFile = `${this.$config.send_url}Api/Upload/UploadImages2?token=${encodeURIComponent(this.$utils.getloc("token"))}&userid=${this.us.userid}`
    this.UploadVideoFile = `${this.$config.send_url}Api/Upload/UserUploadVedio?token=${encodeURIComponent(this.$utils.getloc("token"))}&userid=${this.us.userid}`
    this.id = this.$route.query.id
    this.getdata()
  },
  methods: {
    getCover(value) {
			this.shopGoods2Model[this.active].img = value
		},
		getFileList(value) {
			let slideList = [];
			value.forEach(item => {
				let serverFile = "upload/"
				if (item.type == "video") {
					serverFile = "uploadVedio/"
				}
				item.url = `${this.$config.send_url}${serverFile}${item.serverFileName}`
				slideList.push(item)
			});
			this.shopGoods2Model[this.active].slideList = slideList
		},
    getimg(img) {
			if (img != "") {
				return this.$config.send_url + "upload/" + img;
			}
		},
    editoChange(val) {
      this.shopGoods2Model[this.active].content = val
    },
    onOversize(file) {
      // 图片大小校验
      this.$q.dialog({
        message: "请上传5M内的图片",
      });
    },
    beforeRead(file) {
      // 图片格式校验
      if (
        file.type == "image/jpeg" ||
        file.type == "image/png" ||
        file.type == "image/gif"
      ) {
        return true;
      } else {
        this.$q.dialog({
          message: "请上传 jpg 格式图片",
        });
        return false;
      }
    },
    afterRead(file) {
      let _this = this;
      let _formdata = new FormData(); //创建form对象
      _formdata.append("file", file.file);

      let _posturl =
        _this.$config.send_url +
        "Api/Upload/UploadImages?userid=" +
        _this.us.userid + "&token=" + encodeURIComponent(_this.$utils.getloc("token"));
      let _config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      Axios.post(_posturl, _formdata, _config)
        .then((res) => {
          if (res.data.code == 100) {
            let data = res.data.data.imgname;
            _this.shopGoods2Model[_this.active].img.push(data)
            console.log(_this.shopGoods2Model)
          } else {
            this.$q.dialog({
              message: res.data.msg,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    onDelete(e, detail) {
      this.shopGoods2Model[this.active].img.splice(detail.index, 1)
    },
    // 添加规格
    addModel() {
      this.shopGoods2Model.push({
        name: `规格${this.shopGoods2Model.length + 1}`,
        price: 0,
        stock: 0,
        sales: 0,
        content: "",
        img: [],
        fileList: [],
        state: 1,
        sort: 0,
        rangli: 0
      });
    },
    delModel() {
      let _this = this
      if(this.shopGoods2Model.length > 1) {
        this.$q.dialog({
          message: `确定要删除规格${this.shopGoods2Model[this.active].name}吗？`,
          cancel: true,
        }).onOk(() => {
          let delete_id = _this.shopGoods2Model[_this.active].id
					if (delete_id) {
						_this.$request.post("api/ShopGoods/DeleteModel", {
							
              userid: _this.us.userid,
							delete_id: delete_id ? delete_id : 0,
							goodsid: _this.id
						}, (res) => {
							_this.shopGoods2Model.splice(_this.active, 1)
							Toast(res.data.msg);
							if (_this.active != 0) {
								_this.active--
							}
						})
					} else {
						_this.shopGoods2Model.splice(this.active, 1)
						Toast("删除成功");
						if (_this.active != 0) {
							_this.active--
						}
					}
        })
      } else {
        Toast('至少有一种规格');
      }
    },
    panduan() {
      let failed = this.shopGoods2Model.find(item => {
        return item.name.length == "" || item.img.length == 0
      })
      let mod = {pass: false, msg: ""}
      if(this.goods.number == "") {
        mod.msg = "编号不能为空"
      } else if(this.goods.name == "") {
        mod.msg = "名称不能为空"
      } else if(failed && failed.name == "") {
        mod.msg = "规格名称不能为空"
      } else if(failed && failed.img.length == 0) {
        mod.msg = "规格" + failed.name + "图片不能为空"
      } else {
        mod.pass = true
      }
      if(!mod.pass) {
        Toast(mod.msg)
      }
      return mod.pass
    },
    // 选择分类
    onConfirm(e) {
      this.fenlei = e
      this.goods.class1Id = e.class1Id
      this.goods.class2Id = e.id
      this.show = false
    },
    getdata() {
      let _this = this
      _this.$request.post(
        "api/ShopGoods/UserGet",
        {
          userid: _this.us.userid,
          id: _this.id
        },
        (res) => {
          if(res.data.code == 100) {
            let data = res.data.data
            data.dbShopGoodsModel.forEach(item => {
              item.img = item.img.split(",")
              item.fileList = []
              item.img.forEach(item2 => {
                item.fileList.push({
                  url: _this.getimg(item2)
                })
                if (item.slide) {
                  item.slideList = JSON.parse(item.slide)
                }
              })
            })
            _this.shopGoods2Model = data.dbShopGoodsModel
            _this.goods = data
            _this.getFenlei()
            // console.log(data)
          }
        }
      )
    },
    getFenlei() {
      let _this = this
      _this.$request.post(
        "api/ShopGoods/GetFenlei",
        {},
        (res) => {
          if(res.data.code == 100) {
            let data = res.data.data
            data.forEach(item => {
              item.text = item.name
            })
            if (data.length > 0) {
              _this.fenlei = data.find(item => item.id == _this.goods.class2Id)
              _this.fenleiList = data
            }
          }
        }
      )
    },
    update() {
      let _this = this
      if(!this.panduan()) return
      _this.$q.loading.show({
        message: _this.$i18n.t("修改中...")
      })
      let goodsmodel = JSON.parse(JSON.stringify(_this.shopGoods2Model))
      goodsmodel.forEach(item => {
        item.img = item.img.toString()
      })
      let new_goods = JSON.parse(JSON.stringify(_this.goods))
      new_goods.dbShopGoodsModel = []
      _this.$request.post(
        "api/ShopGoods/Update",
        {
          userid: _this.us.userid,
          goods: JSON.stringify(new_goods),
          goodsmodel: JSON.stringify(goodsmodel)
        },
        (res) => {
          _this.$q.loading.hide();
          _this.$q.dialog({
            message: res.data.msg,
          })
        }
      )
    }
  }
}
</script>

<style scoped>
.add {
  padding: 10px 10px 60px;
  min-height: 100vh;
  background: rgba(242, 242, 242, 1);
}

.form {
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
}

.cell {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 16px;
}

.cell::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 16px;
  right: 16px;
  border-bottom: 1px solid #ebedf0;
  transform: scaleY(.5);
}

.cell-label {
  margin-right: 12px;
  width: 86px;
  color: #646566;
}

.sku-form {
  margin-top: 10px;
  padding: 0 10px 10px;
}

.sku-btn {
  padding: 2px 10px;
  margin-right: 10px;
  font-size: 12px;
  color: #fff;
  border-radius: 4px;
  background-color: rgb(254, 146, 0);
}

.sku-btn:nth-child(2) {
  background-color: #ee0a24;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #ee0a24;
  border-top: 1px solid rgba(238,233,233,.4);
  z-index: 9;
}
</style>